import React from 'react'
import * as S from './styled'
import { H2White, UnderlineYellow } from '../../Typography'
import Form from './../../Default/FormSimpleAjax'
const WriteUs = () => {
  return (
    <S.Wrapper className="container">
      <H2White>Napište nám</H2White>
      <UnderlineYellow />
      <Form name="Homepage" />
    </S.Wrapper>
  )
}

export default WriteUs
