import React from 'react'

import Layout from '../components/Default/Layout'

import LandingPhoto from '../components/Homepage/LandingPhoto'
import EKPNumbers from '../components/Homepage/EKPNumbers'
import WriteUs from '../components/Homepage/WriteUs'
import { graphql } from 'gatsby'

const Homepage = ({ data: { page } }, { location }) => {
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      location={location}
      crumbLabel=""
    >
      <LandingPhoto />
      <EKPNumbers />
      {/* TODO: Remove styled components -> Write pure css */}
      <br />
      <WriteUs />
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage {
    page: markdownRemark(frontmatter: { slug: { eq: "/" } }) {
      ...Meta
    }
    blogs: allMarkdownRemark(
      filter: {
        fields: { contentType: { eq: "novinky-blog" } }
        frontmatter: { categories: { elemMatch: { category: { eq: "Blog" } } } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            czDate: date(locale: "cs", formatString: "DD. MM. YYYY")
            categories {
              category
            }
            image {
              childImageSharp {
                fixed(quality: 100, height: 180, width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    news: allMarkdownRemark(
      filter: {
        fields: { contentType: { eq: "novinky-blog" } }
        frontmatter: {
          categories: { elemMatch: { category: { eq: "Novinky" } } }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            czDate: date(locale: "cs", formatString: "DD. MM. YYYY")
            categories {
              category
            }
            image {
              childImageSharp {
                fixed(quality: 100, height: 180, width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
