import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import "./styles.css";
import Img from "gatsby-image";

const HomepageLanding = ({ photo, ekpLogo }) => (
  <div className="LandingPhoto">
    <div className="row align-items-center">
      <div className="col-xl-11 offset-xl-1 col-lg-12">
        <div className="container row justify-content-center">
          <div className="h1-mobile col-12">
            <h1 style={{ display: "none" }}>EK Partners, s.r.o.</h1>
            <h2 style={{ display: "none" }}>daňoví a účetní poradci</h2>
            <Img
              className="homepage-logo-padding"
              fixed={ekpLogo.fixed}
              alt="Homepage photo"
            />
          </div>
          <div className="h1-desktop col-12">
            <h2 className="h2-mobile" style={{ display: "none" }}>
              EK Partners, s.r.o.
            </h2>
            <h2 style={{ display: "none" }}>daňoví a účetní poradci</h2>
            <Img
              className="homepage-logo-padding"
              fixed={ekpLogo.fixed}
              alt="Homepage photo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const HomepageLandingData = () => {
  const { homepageLanding, ekpLogo } = useStaticQuery(graphql`
    query {
      ekpLogo: file(relativePath: { eq: "homepage/ekp_logo_homepage_CZ.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      homepageLanding: file(
        relativePath: { eq: "homepage/landing_photo.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HomepageLanding
      ekpLogo={ekpLogo.childImageSharp}
      photo={homepageLanding.childImageSharp}
    />
  );
};

export default HomepageLandingData;
